import gql from 'graphql-tag'

const jobUpdateSubscription = gql`
  subscription {
    jobUpdateSubscription {
      id
      name
      description
      status {
        id
        name
        color
        urutan
        job_type {
          id
          name
        }
      }
      priority {
        id
        name
      }
      expected_close_date
      customer {
        id
        name
      }
      created_by {
        id
        first_name
        last_name
      }
      created_at
      updated_at
      deleted_at
      assignedTo {
        id
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
      }
      custom_attribute_values
    }
  }
`

export default jobUpdateSubscription
