<template>
  <v-card
    :loading="loadingTasks"
    class="d-flex flex-column mb-6"
    :max-height="$vuetify.breakpoint.mdAndUp ? '40vh' : '80vh'"
  >
    <div class="d-flex justify-space-between pa-3">
      <span class="text-subtitle-1 font-medium">Task Job</span>
      <!-- <v-btn
        color="primary"
        small
      >
        Buat Task
      </v-btn> -->
    </div>
    <v-divider />
    <div
      id="taskList"
      v-scroll:#taskList="listenScrollFetchMore"
      class="task-list"
    >
      <TaskCard
        v-for="(task, index) in taskList"
        :key="`${task.id}${index}`"
        :is-job-prospect-task="true"
        :task="task"
        @check="checkTask(task.id, task.completed_at != null)"
        @detail="$refs.taskDetail.show(task.id)"
        @delete="deleteTask"
      />
      <div
        class="d-flex my-2"
        style="min-height: 48px"
      >
        <v-progress-circular
          v-if="loadingFetchMore"
          indeterminate
          color="primary"
          class="mx-auto"
        />
      </div>
    </div>
    <TaskDetail
      ref="taskDetail"
      @refetch="fetchTasks"
    />
  </v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import TaskCard from '@/views/activity/task/TaskCard.vue'
import useTask from '@/composables/useTask'
import TaskDetail from '@/views/activity/task/TaskDetail.vue'

export default {
  components: {
    TaskCard,
    TaskDetail,
  },
  props: {
    jobId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      loadingTasks, taskList, fetchTasks, checkTask, loadingFetchMore,
      listenScrollFetchMore, deleteTask,
    } = useTask({
      filter: ref({
        job_id: props.jobId,
        sort: 'end_date',
        order: 'DESC',
      }),
    })

    onMounted(() => {
      fetchTasks()
    })

    return {
      loadingTasks,
      taskList,
      loadingFetchMore,
      fetchTasks,
      checkTask,
      listenScrollFetchMore,
      deleteTask,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.task-list {
  @include style-scroll-bar();
  max-height: calc(60vh + 153px);
  overflow-y: scroll;
}
</style>
