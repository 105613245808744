<template>
  <div v-if="detail">
    <app-breadcrumb
      :title="detail.name"
      hide-breadcrumbs
    >
      <template #action>
        <v-btn
          v-if="$can('update', 'Job')"
          outlined
          small
          color="warning"
          class="mr-2"
          @click="$refs.jobForm.update(+id)"
        >
          Edit
        </v-btn>
        <v-btn
          v-if="$can('del', 'Job')"
          outlined
          small
          color="error"
          @click="removeJob()"
        >
          Hapus
        </v-btn>
      </template>
      <template
        #title
      >
        <h3
          v-if="!editMode"
          class="mr-8 mb-0"
          @click="editName"
        >
          {{ detail.name }}
        </h3>
        <v-text-field
          v-if="editMode"
          v-model="detail.name"
          flat
          dense
          autofocus
          hide-details="auto"
          @blur="editMode = false"
        />
        <!-- @blur="changeJob(detail.id, {name: detail.name}).then(() => {editMode = false})" -->
      </template>
    </app-breadcrumb>
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
        xl="3"
        class="vertical-list"
      >
        <PerfectScrollbar
          class="scroll-bar px-4 pt-0 pb-4"
        >
          <v-progress-linear
            :value="jobProgress"
            rounded
            class="rounded-b-0"
          />
          <v-card
            v-if="detail"
            class="d-flex flex-column pa-4 rounded-t-0 mb-6"
          >
            <!-- <span class="text-h6 text--primary font-weight-bold">
            {{ detail && detail.name }}
          </span> -->
            <div>
              <span class="text--disabled text-caption d-block">Tags</span>
              <v-chip-group>
                <v-chip
                  v-for="ref in detail.referenceTags"
                  :key="ref.id"
                  :color="ref.tag && ref.tag.color"
                  small
                  class="mt-1"
                >
                  {{ ref.tag && ref.tag.name }}
                </v-chip>
              </v-chip-group>
            </div>
            <span class="text-caption text--disabled mt-2">Dibuat Oleh</span>
            <div class="d-flex mt-1">
              <v-avatar
                color="primary"
                class="v-avatar-light-bg mr-2"
                size="40"
              >
                {{ detail.created_by ? avatarText(detail.created_by.name) : '' }}
              </v-avatar>
              <div class="d-flex flex-column">
                <span class="text-subtitle-2 primary--text">{{ detail.created_by && detail.created_by.name }}</span>
                <span class="text-caption text--disabled font-medium">{{ detail.created_by && detail.created_by.email }}</span>
              </div>
            </div>

            <div>
              <span class="text--disabled text-caption d-block mt-2">Prioritas</span>
              <v-menu
                offset-y
                attach
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    :color="detail.priority && detail.priority.color"
                    small
                    class="mt-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="getJobPriority([detail.status.job_type.id])"
                  >
                    {{ detail.priority && detail.priority.name }}
                    <v-icon
                      size="16"
                      class="ms-2"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </v-chip>
                </template>
                <v-list
                  nav
                  dense
                >
                  <v-list-item
                    v-for="priority in jobPriorityList"
                    :key="priority.id"
                    :color="priority.color"
                  >
                    <v-chip
                      :color="priority.color"
                      small
                      class="mt-1"
                    >
                      {{ priority.name }}
                    </v-chip>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="d-flex mb-2">
              <div class="mr-2">
                <span class="text--disabled text-caption d-block mt-2">Pipeline</span>
                <v-chip
                  color="primary"
                  small
                  class="mt-1"
                >
                  {{ detail.status && detail.status.job_type.name }}
                </v-chip>
              </div>
              <div>
                <span class="text--disabled text-caption d-block mt-2">Status</span>
                <div class="d-flex mt-1">
                  <v-menu
                    offset-y
                    attach
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        :color="detail.status && detail.status.color "
                        small
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"

                        @click="getJobStatus([detail.status.job_type.id])"
                      >
                        {{ detail.status && detail.status.name }}
                        <v-icon
                          size="16"
                          class="ms-2"
                        >
                          {{ icons.mdiPencil }}
                        </v-icon>
                      </v-chip>
                    </template>
                    <v-list
                      nav
                      dense
                    >
                      <v-list-item
                        v-for="status in jobStatusList"
                        :key="status.id"
                        :color="status.color"
                        @click="changeStatus(detail.id, status.id)"
                      >
                        <v-chip
                          :color="status.color"
                          small
                          class="mt-1"
                        >
                          {{ status.name }}
                        </v-chip>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    rounded
                    x-small
                    text
                    outlined
                  >
                    <v-icon
                      size="16"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <span class="text--disabled text-caption">Tanggal Ditutup</span>
            <span class="text-subtitle-2 font-medium">{{ dateFormat(detail && detail.expected_close_date, 4) }}</span>
            <span class="text--disabled text-caption mt-2">Tanggal Dibuat</span>
            <span class="text-subtitle-2 font-medium">{{ dateFormat(detail && detail.created_at, 3) }}</span>
            <span class="text--disabled text-caption mt-2">Sisa waktu sebelum job ditutup</span>
            <span class="font-weight-bold primary--text text-h6">
              <CountdownTimer
                :date="detail && detail.expected_close_date"
                @update="setCountDownBar"
              />
            </span>
            <span class="text--disabled text-caption mt-2">Ditugaskan ke</span>
            <div
              v-if="detail.assignedTo"
              class="d-flex flex-row mt-2"
            >
              <limit-elements
                v-if="detail.assignedTo.length"
                :elements="detail.assignedTo"
                :limit="3"
                class="v-avatar-group mr-auto"
                :class="rootThemeClasses"
              >
                <template #default="el">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="36"
                        class="primary"
                        v-on="on"
                      >
                        <v-img
                          v-if="el.data.user.photo"
                          :src="el.data.user.photo"
                        />
                        <span
                          v-else
                          class="white--text"
                        >
                          {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                        </span>
                      </v-avatar>
                    </template>
                    <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
                  </v-tooltip>
                </template>
                <template #others="el">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="36"
                        class="primary mb-1"
                        v-on="on"
                      >
                        <v-img
                          v-if="el.data.user.photo"
                          :src="el.data.user.photo"
                        />
                        <span
                          v-else
                          class="white--text"
                        >
                          {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                        </span>
                      </v-avatar>
                    </template>
                    <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
                  </v-tooltip>
                </template>
                <template #others-activator="el">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        size="36"
                        color="white"
                        class="primary--text text-caption"
                        v-on="on"
                      >
                        <span>+{{ el.data.limit }}</span>
                      </v-avatar>
                    </template>
                    <span>Lihat User Lain</span>
                  </v-tooltip>
                </template>
              </limit-elements>
              <v-menu
                v-model="showAssignForm"
                :close-on-content-click="false"
                left
              >
                <template v-slot:activator="menu">
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="tooltip">
                      <v-btn
                        v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                        text
                        outlined
                        small
                        rounded
                        color="primary"
                        class="ml-auto mt-1"
                        v-on="{ ...menu.on, ...tooltip.on }"
                        @click.stop
                      >
                        <v-icon
                          size="18"
                        >
                          {{ icons.mdiAccountPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Tugaskan ke Pengguna</span>
                  </v-tooltip>
                </template>

                <assign-object
                  ref="assignObject"
                  :job-id="+id"
                  :data="detail.assignedTo"
                  @success="fetchDetail(); showAssignForm = false"
                  @failed="$refs.assignObject.initForm()"
                />
              </v-menu>
            </div>
          </v-card>
          <v-card class="d-flex flex-column mb-6">
            <div class="pa-3 d-flex justify-space-between flex-wrap align-center">
              <span class="text-subtitle-1 font-medium">Atribut lainnya</span>
              <v-tooltip
                top
              >
                <template #activator="{on, attrs}">
                  <v-card
                    class="d-flex horizontal-list-button"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      class="align-self-center"
                      elevation="0"
                      small
                      outlined
                      color="primary"
                      @click="$refs.customAttributesList.open()"
                    >
                      <v-icon
                        size="22"
                      >
                        {{ icons.mdiCog }}
                      </v-icon>
                    </v-btn>
                  </v-card>
                </template>
                <span>Edit / Tambah atribut</span>
              </v-tooltip>
            </div>
            <v-divider />
            <div class="d-flex flex-column">
              <div class="d-flex justify-space-between align-center my-2">
                <PerfectScrollbar
                  :options="{
                    useBothWheelAxes:true
                  }"
                  class="horizontal-list px-4 pt-0 pb-4"
                >
                  <job-info
                    v-for="attr in customAttributesArray"
                    :key="attr.id"
                    :title="attr.custom_attribute.name"
                    class="mt-2"
                  >
                    {{ getAttributeValue(attr) }}
                  </job-info>
                </perfectscrollbar>
              </div>
            </div>
          </v-card>
          <job-detail-customer :job-data="detail" />
        </PerfectScrollbar>
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
        xl="9"
      >
        <job-detail-task :job-id="+id" />
        <Timeline
          :ref="timeline"
          type="job"
          name="Job"
          :object-id="id"
        />
      </v-col>
    </v-row>
    <comment-box
      v-if="$can('read', 'Comment')"
      :job-id="+id"
    />
    <JobForm
      ref="jobForm"
      @success="fetchDetail(); $refs.timeline.fetch()"
    />
    <custom-attributes-list
      v-if="detail.status"
      ref="customAttributesList"
      :object-id="+id"
      :object-type-id="2"
      :job-type-id="detail.status.job_type.id"
      @update="fetchCustomAttributes(+id, detail.status.job_type.id)"
    />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { mdiAccountPlus, mdiPencil, mdiCog } from '@mdi/js'
import Vue from 'vue'
import useVuetify from '@core/utils/vuetify'
import { ellipsis, avatarText } from '@core/utils/filter'
import AppBreadcrumb from '@/components/misc/AppBreadcrumb.vue'
import CommentBox from '@/components/misc/CommentBox.vue'
import AssignObject from '@/components/inputs/AssignObject.vue'
// import JobDetailLogs from './components/JobDetailLogs.vue'
import JobDetailTask from './components/JobDetailTask.vue'
import JobDetailCustomer from './components/JobDetailCustomer.vue'
import { apolloClient } from '@/vue-apollo'
import { jobDetail } from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import dateFormat from '@/utils/dateFormat'
import JobForm from '@/views/job/components/JobForm.vue'
import { deleteJob } from '@/graphql/mutations'
import router from '@/router'
import LimitElements from '@/components/misc/LimitElements.vue'
import CountdownTimer from '@/components/misc/CountdownTimer.vue'
import Timeline from '@/components/misc/Timeline.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import JobInfo from './components/JobDetailInfo.vue'
import CustomAttributesList from '../custom-attributes/CustomAttributesList.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import jobUpdateSubscription from '@/graphql/subscription/jobUpdateSubscription'
import useJob from '@/composables/useJob'

export default {
  components: {
    JobForm,
    AppBreadcrumb,
    CommentBox,
    AssignObject,
    Timeline,
    JobDetailCustomer,
    JobDetailTask,
    LimitElements,
    CountdownTimer,
    PerfectScrollbar,
    JobInfo,
    CustomAttributesList,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const {
      changeJob, loadingUpdateJob,
      getJobPriority, loadingJobPriority, jobPriorityList,
      getJobStatus, loadingJobStatus, jobStatusList,
      changeJobStatus, loadingJobStatusChange,
    } = useJob()
    const editMode = ref(false)
    const loadingDetail = ref(false)
    const detail = ref({})
    const showAssignForm = ref(false)
    const { rootThemeClasses } = useVuetify()
    const timeline = ref()
    const { customAttributesArray, fetchCustomAttributes, getAttributeValue } = useCustomAttributes({ objectTypeId: 2 })

    const editName = () => {
      editMode.value = true
      console.log(editMode.value)
    }
    const fetchDetail = () => {
      loadingDetail.value = true
      apolloClient.query({
        query: jobDetail,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_id: +props.id,
        },
      }).then(({ data }) => {
        loadingDetail.value = false
        detail.value = data.jobDetail
        fetchCustomAttributes(+props.id, data.jobDetail.status.job_type.id)
        console.log(data)
      }).catch(err => {
        loadingDetail.value = false
        router.push('/error')
        errorHandling(err, 'Detail Job')
      })
    }

    const changeStatus = (jobId, statusId) => {
      changeJobStatus(jobId, statusId).then(() => {
        fetchDetail()
      })
    }

    const jobProgress = ref(100)
    const removeJob = () => {
      Vue.$dialog({
        title: 'Hapus job?',
        body: 'Konfirmasi jika anda ingin menghapus job.',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: deleteJob,
            variables: {
              id: +props.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus job!',
            })
            router.push(`/${store.getters.getCurrentWorkspace.identifier_id}/jobs`)
          }).catch(err => {
            errorHandling(err, 'Hapus Job')
          })
        }
      })
    }
    const setCountDownBar = ({ totalMilis, endMilis }) => {
      jobProgress.value = ((endMilis - totalMilis) / endMilis) * 100
    }
    onMounted(() => {
      fetchDetail()
    })

    const observer = apolloClient.subscribe({
      query: jobUpdateSubscription,
      fetchPolicy: 'no-cache',
    })

    observer.subscribe({
      next(sub) {
        const update = sub.data.jobUpdateSubscription
        console.log(update)
        // detail.value = Object.assign(detail.value, update)
        // console.log(detail.value)
        fetchDetail()
      },
      error(error) {
        console.error(error)
        errorHandling(error)
      },
    })

    return {
      ellipsis,
      avatarText,
      rootThemeClasses,
      loadingDetail,
      detail,
      showAssignForm,
      fetchDetail,
      jobProgress,
      dateFormat,
      removeJob,
      timeline,
      setCountDownBar,
      icons: {
        mdiAccountPlus,
        mdiPencil,
        mdiCog,
      },
      customAttributesArray,
      fetchCustomAttributes,
      getAttributeValue,
      editName,
      editMode,
      changeJob,
      loadingUpdateJob,

      jobPriorityList,
      loadingJobPriority,
      getJobPriority,

      jobStatusList,
      loadingJobStatus,
      getJobStatus,
      changeStatus,
      loadingJobStatusChange,
    }
  },
}
</script>

<style>

.scroll-bar {
  overflow-y: scroll;
  height: calc(100vh - 13.5rem) ;
}
</style>
